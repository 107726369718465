<template>
  <div :class="wrapClass">
    <v-btn
      v-bind="$attrs"
      v-on="$listeners"
      :class="buttonClass"
      :color="parsedColor"
      height="48px"
      depressed
      tile
      :type="type"
      :style="cssVars"
    >
      <slot />
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    align: {
      type: String,
      default: "left",
      validator: (val) => ["left", "center", "right"].includes(val),
    },
    dark: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "secondary",
    },
    textColor: {
      type: String,
    },
    type: {
      type: String,
      default: "button",
    },
  },
  computed: {
    wrapClass() {
      const alignClassOptions = {
        left: "justify-start",
        center: "justify-center",
        right: "justify-end",
      };

      const wrapClass = ["d-flex", alignClassOptions[this.align]];

      return wrapClass;
    },
    buttonClass() {
      const buttonClass = ["button-widget text-button px-8"];

      return buttonClass;
    },
    parsedColor() {
      return this.dark ? "white" : this.color;
    },
    parsedTextColor() {
      const primaryColor = this.$vuetify.theme.themes.light.primary;

      if (!this.textColor) {
        return primaryColor;
      }

      return this.textColor;
    },
    cssVars() {
      return {
        "--text-color": this.textColor,
      };
    },
  },
};
</script>

<style>
.button-widget {
  color: var(--text-color) !important;
}
</style>
