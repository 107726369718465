<template>
  <div class="page-template">
    <header v-if="title" class="page-template-title py-md-2">
      <v-container :class="titleContainerClass">
        <span
          class="d-block text-h4 text-md-h3 text-uppercase font-weight-black"
        >
          {{ title }}
        </span>
      </v-container>
    </header>
    <slot name="top"></slot>

    <component :is="containerComponent" :class="containerClass">
      <LoadingWidget v-if="loading" />

      <template v-else>
        <slot />
      </template>
    </component>

    <slot name="bottom"></slot>
  </div>
</template>

<script>
import LoadingWidget from "../widgets/LoadingWidget.vue";
import TitleWidget from "../widgets/TitleWidget.vue";
import { VContainer } from "vuetify/lib/components";

export default {
  props: {
    title: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    noContainer: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    containerComponent() {
      return !this.noContainer ? "v-container" : "div";
    },
    smAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    titleContainerClass() {
      return ["d-flex justify-center align-center flex-column"];
    },
    containerClass() {
      return [!this.noContainer && "py-4 py-lg-8"];
    },
  },
  components: {
    LoadingWidget,
    TitleWidget,
    VContainer,
  },
};
</script>

<style lang="scss">
.page-template {
  > header {
    position: relative;
    background-color: var(--v-primary-base);
  }

  &-title {
    color: white;
  }

  .page-template-title .container span {
    width: 100%;
    text-align: center !important;
  }
}
</style>
