<template>
  <div class="carousel-widget" :style="{ '--gap': `${gap / 2}px` }">
    <div class="position-relative">
      <hooper-container
        ref="hooperContainer"
        :style="{ height }"
        :settings="hooperSettings"
        @slide="updateCarousel"
      >
        <hooper-slide v-for="(item, i) in items" :key="i">
          <slot name="item" :item="item" />
        </hooper-slide>
      </hooper-container>
      <Navigation
        v-if="nav && pages > 1"
        @prev="slidePrev"
        @next="slideNext"
        :dark="dark"
        :position-y="navPositionY"
        :inside="navInside"
        :disabled-prev="disabledPrev"
        :disabled-next="disabledNext"
      />
    </div>

    <Dots
      v-if="dots && pages > 1"
      @change="(val) => slideTo(val)"
      :count="pages"
      :actived="dotActive"
      :dark="dark"
    />
  </div>
</template>

<script>
import Dots from "./Dots.vue";
import Navigation from "./Navigation.vue";

export default {
  props: {
    items: {
      type: Array,
      required: true,
      validator: (val) => val.length > 0,
    },
    dots: {
      type: Boolean,
      default: false,
    },
    nav: {
      type: Boolean,
      default: false,
    },
    // Indica a posição da navegação no eixo Y em porcentagem de acordo com o tamanho do componente.
    navPositionY: {
      type: Number,
      default: 50,
    },
    navInside: {
      type: Boolean,
      default: false,
    },
    autoPlay: {
      type: Boolean,
      default: true,
    },
    infiniteScroll: {
      type: Boolean,
      default: true,
    },
    playSpeed: {
      type: Number,
      default: 6000,
    },
    height: {
      type: String,
      default: "auto",
    },
    itemsToShow: {
      type: Object,
      default: () => ({
        xs: 2,
        sm: 4,
        md: 5,
        lg: 5,
        xl: 5,
      }),
    },
    dark: {
      type: Boolean,
      default: false,
    },
    gap: {
      type: Number,
      default: 16,
    },
    mouseDrag: {
      type: Boolean,
      default: true,
    },
    touchDrag: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    dotActive: 1,
    carouselData: 0,
  }),
  computed: {
    pages() {
      return Math.ceil(this.items.length / this.currentItemsToShow);
    },
    currentItemsToShow() {
      return this.itemsToShow[this.$vuetify.breakpoint.name];
    },
    hooperSettings() {
      const thresholds = this.$vuetify.breakpoint.thresholds;
      return {
        itemsToShow: this.itemsToShow.xs,
        itemsToSlide: 1,
        hoverPause: true,
        wheelControl: false,
        trimWhiteSpace: true,
        playSpeed: this.playSpeed,
        infiniteScroll:
          !!this.infiniteScroll && this.items.length > this.itemsToShow.xs,
        autoPlay: !!this.autoPlay && this.items.length > this.itemsToShow.xs,
        mouseDrag: this.mouseDrag && !!this.items.length,
        touchDrag: this.touchDrag && !!this.items.length,
        breakpoints: {
          [thresholds.xs]: {
            itemsToShow: this.itemsToShow.sm,
            infiniteScroll:
              !!this.infiniteScroll && this.items.length > this.itemsToShow.sm,
            autoPlay:
              !!this.autoPlay && this.items.length > this.itemsToShow.sm,
          },
          [thresholds.sm]: {
            itemsToShow: this.itemsToShow.md,
            infiniteScroll:
              !!this.infiniteScroll && this.items.length > this.itemsToShow.md,
            autoPlay:
              !!this.autoPlay && this.items.length > this.itemsToShow.md,
          },
          [thresholds.md]: {
            itemsToShow: this.itemsToShow.lg,
            infiniteScroll:
              !!this.infiniteScroll && this.items.length > this.itemsToShow.lg,
            autoPlay:
              !!this.autoPlay && this.items.length > this.itemsToShow.lg,
          },
          [thresholds.lg]: {
            itemsToShow: this.itemsToShow.xl,
            infiniteScroll:
              !!this.infiniteScroll && this.items.length > this.itemsToShow.xl,
            autoPlay:
              !!this.autoPlay && this.items.length > this.itemsToShow.xl,
          },
        },
      };
    },
    mobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    disabledPrev() {
      if (this.infiniteScroll) {
        return false;
      }

      return this.carouselData < 1;
    },
    disabledNext() {
      if (this.infiniteScroll) {
        return false;
      }

      return this.carouselData >= this.items.length - 1;
    },
  },
  methods: {
    slidePrev() {
      this.$refs.hooperContainer.slidePrev();
    },
    slideNext() {
      this.$refs.hooperContainer.slideNext();
    },
    updateCarousel(payload) {
      if (this.infiniteScroll) {
        this.carouselData =
          payload.currentSlide >= 0 ? payload.currentSlide : this.items.length;
      } else {
        this.carouselData = payload.currentSlide;
      }
    },
    setDotFromSlideIndex(slideIndex) {
      slideIndex++;
      const slide =
        slideIndex <= this.items.length && slideIndex > 0 ? slideIndex : 1;
      const doActive = Math.ceil(slide / this.currentItemsToShow);
      this.dotActive =
        doActive < 0 ? 0 : doActive > this.pages ? this.pages : doActive;
    },
    slideTo(dotIndex) {
      const slideIndex = Math.floor((dotIndex - 1) * this.currentItemsToShow);
      this.$refs.hooperContainer.slideTo(slideIndex);
      this.$refs.hooperContainer.restartTimer();
    },
  },
  watch: {
    carouselData() {
      this.$refs.hooperContainer.slideTo(this.carouselData);
      this.setDotFromSlideIndex(this.carouselData);
    },
    breakpoints() {
      this.$refs.hooperContainer.slideTo(0);
      this.$refs.hooperContainer.restartTimer();
    },
  },
  components: {
    Dots,
    Navigation,
  },
};
</script>

<style lang="scss">
.carousel-widget {
  margin: 0px calc(0px - var(--gap));

  .hooper {
    &-slide {
      height: auto;
      padding: 0px var(--gap);
    }

    ul,
    ol {
      padding-left: 0 !important;
    }
  }
}
</style>
