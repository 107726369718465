<template>
  <div :class="className" class="title-widget">
    <component :is="component" class="text-decoration-none" v-bind="attrs">
      <slot />
      <v-icon v-if="link" class="mb-2" small> fas fa-external-link-alt</v-icon>
    </component>
  </div>
</template>

<script>
export default {
  props: {
    align: {
      type: String,
      default: "center",
      validator: (val) => ["left", "center", "right"].includes(val),
    },
    small: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    link: {
      type: String,
    },
  },
  computed: {
    className() {
      const alignClass = {
        left: "text-left align-start",
        center: "text-center align-center",
        right: "text-right align-end",
      };

      const className = [
        "d-flex flex-column justify-center text-uppercase font-weight-bold title-widget",
        alignClass[this.align],
        !this.dark ? "title-widget--light" : "title-widget--dark",
        !this.small && "text-h5 text-md-h4",
        this.link && "title-widget--link",
      ];

      return className;
    },
    attrs() {
      const attrs = {};

      if (this.link) {
        attrs.to = this.link;
      }

      return attrs;
    },
    component() {
      return this.link ? "router-link" : "span";
    },
  },
};
</script>

<style lang="scss">
.title-widget--light {
  --text: var(--v-secondary-base);
  --decoration: var(--v-secondary-base);
  --hover-text: var(--v-primary-lighten1);
}
.title-widget--dark {
  --text: #ffffff;
  --decoration: var(--v-primary-base);
  --hover-text: #ccc;
}

.title-widget {
  text-decoration: none;

  > * {
    color: var(--text) !important;
  }
}

.title-widget--link a {
  transition: 0.3s;

  i {
    opacity: 0;
    color: inherit !important;
  }

  &:hover {
    color: var(--hover-text) !important;

    i {
      opacity: 1;
    }
  }
}
</style>
