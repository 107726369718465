import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
// import ListCustomView from "../views/ListCustomView.vue";
// import ArticleCustomView from "../views/ArticleCustomView.vue";
// import NewsCard from "../components/cards/NewsCard.vue";
import ContactView from "../views/ContactView.vue";
import ContactResponseView from "../views/ContactResponseView.vue";
import PolicyPrivacyView from "../views/PolicyPrivacyView.vue";
import Error404View from "../views/Error404View.vue";

Vue.use(VueRouter);

const routes = [
  {
    name: "",
    path: "/",
    component: HomeView,
  },
  // {
  //   name: "Notícias",
  //   path: "/noticias",
  //   component: ListCustomView,
  //   meta: {
  //     mod: "news",
  //     cardComponent: NewsCard,
  //   },
  // },
  // {
  //   name: "Notícia",
  //   path: "/noticias/:item",
  //   component: ArticleCustomView,
  //   meta: {
  //     mod: "news",
  //   },
  // },
  {
    name: "Fale Conosco",
    path: "/fale-conosco",
    component: ContactView,
  },
  {
    name: "Obrigado!",
    path: "/fale-conosco/obrigado",
    component: ContactResponseView,
  },
  {
    name: "Política de Privacidade",
    path: "/politica-de-privacidade",
    component: PolicyPrivacyView,
  },
  {
    path: "/erro404",
    name: "Erro 404",
    component: Error404View,
  },
  {
    path: "*",
    component: Error404View,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes,
});

export default router;
