<template>
  <v-app>
    <LoadingWidget v-if="loading" />

    <template v-else>
      <v-sheet
        color="transparent"
        :style="`background-image: url(${
          files + config.img_bg + '?resize=1&w=1920'
        });`"
        class="home-view"
      >
        <div
          class="
            d-flex
            align-center
            flex-column
            justify-space-between
            home-view-content
          "
        >
          <v-container fluid>
            <div class="d-flex justify-center">
              <img
                class="d-block home-view-content-logo"
                :src="files + config.img_logo"
              />
            </div>

            <div class="px-3 mt-10 gallery">
              <img
                v-for="(img, keyItems) in gallery"
                :key="keyItems"
                @click="showGallery(keyItems)"
                class="d-block cursor-pointer"
                :src="img"
                width="100%"
              />
            </div>
          </v-container>
        </div>
      </v-sheet>
    </template>

    <GalleryWidget />
  </v-app>
</template>

<script>
import LoadingWidget from "./components/widgets/LoadingWidget.vue";
import GalleryWidget from "./components/widgets/GalleryWidget.vue";

export default {
  data: () => ({
    loading: true,
  }),
  computed: {
    config() {
      return this.$rest("config").item;
    },
    item() {
      return this.$rest("gallery").item;
    },
    gallery() {
      const images = this.item.img;
      if (!images || !images.list || !images.list.length) return [];
      return images.list.map(({ path }) => {
        return this.files + path;
      });
    },
    xsOnly() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  methods: {
    showGallery(index) {
      window.showGalleryWidget(this.gallery, index);
    },
  },
  created() {
    const config = this.$rest("config").get({
      save: (state, data) => (state.item = data),
    });

    const gallery = this.$rest("gallery").get({
      save: (state, data) => (state.item = data),
    });

    Promise.all([config, gallery]).finally(() => (this.loading = false));
  },
  components: {
    LoadingWidget,
    GalleryWidget,
  },
};
</script>

<style lang="scss" scoped>
.home-view {
  min-height: 100vh;
  height: 100%;
  background-size: cover;
  background-position: center;

  &-content {
    padding: 80px 0 80px 0;
    min-height: 100vh;
    height: 100%;
    width: 100%;

    &-logo {
      width: 400px;
      height: 136px;
      object-fit: contain;
      @media (max-width: 600px) {
        width: 100%;
        padding: 0 20px 0 20px;
      }
    }
  }
}

.gallery {
  display: grid;
  justify-content: center;
  gap: 12px;
  grid-template-columns: repeat(auto-fit, 300px);

  @media screen and (max-width: 599.9px) {
    grid-template-columns: 1fr 1fr;
  }

  img {
    aspect-ratio: 1;
    object-fit: cover;
  }
}
</style>
