<template>
  <div>
    <SlideShowSection />
  </div>
</template>

<script>
import SlideShowSection from "../components/sections/SlideShowSection.vue";

export default {
  components: {
    SlideShowSection,
  },
};
</script>
