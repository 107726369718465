<template>
  <Tinybox :images="images" v-model="index" />
</template>

<script>
import Tinybox from "vue-tinybox";

/**
 * Instancie esse componente na App.vue
 * e use a função window.showGalleryWidget para exibir uma galeria.
 */
export default {
  data: () => ({
    index: null,
    images: [], //  { src }[]
  }),
  created() {
    window.showGalleryWidget = (images, index) => {
      this.images = images;

      if (this.images.length) {
        this.index = index;
      }
    };
  },
  components: {
    Tinybox,
  },
};
</script>
