var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items.length)?_c('CarouselWidget',{staticClass:"slide-show-section",attrs:{"items":_vm.items,"itemsToShow":{
    xs: 1,
    sm: 1,
    md: 1,
    lg: 1,
    xl: 1,
  },"gap":0,"nav":"","nav-inside":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
  var item = ref.item;
return [_c('v-card',_vm._b({attrs:{"flat":"","tile":""}},'v-card',_vm.getLinkAttrs(item.link, _vm.server),false),[_c('img',{staticClass:"slide-show-section_img d-block",attrs:{"src":_vm.files +
          (_vm.xsOnly && item.mobile
            ? item.mobile + '?resize=1&w=600'
            : item.desktop + '?resize=1&w=1920'),"width":"100%"}})])]}}],null,false,4266464894)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }