<template>
  <CarouselWidget
    v-if="items.length"
    class="slide-show-section"
    :items="items"
    :itemsToShow="{
      xs: 1,
      sm: 1,
      md: 1,
      lg: 1,
      xl: 1,
    }"
    :gap="0"
    nav
    nav-inside
  >
    <template #item="{ item }">
      <v-card v-bind="getLinkAttrs(item.link, server)" flat tile>
        <img
          :src="
            files +
            (xsOnly && item.mobile
              ? item.mobile + '?resize=1&w=600'
              : item.desktop + '?resize=1&w=1920')
          "
          class="slide-show-section_img d-block"
          width="100%"
        />
      </v-card>
    </template>
  </CarouselWidget>
</template>

<script>
import CarouselWidget from "../widgets/CarouselWidget/Index.vue";
import { getLinkAttrs } from "../../utils/getLinkAttrs";

const _config = {
  mod: "slides",
};

export default {
  computed: {
    items() {
      return this.$rest(_config.mod).list.filter(({ desktop }) => !!desktop);
    },
    xsOnly() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  methods: {
    getLinkAttrs,
  },
  beforeCreate() {
    this.$rest(_config.mod).get();
  },
  components: {
    CarouselWidget,
  },
};
</script>

<style lang="scss" scoped>
.slide-show-section {
  aspect-ratio: 1920 / 680;

  @media screen and (max-width: 600px) {
    aspect-ratio: 600 / 600;
  }

  &_img {
    height: 100%;
    object-fit: cover;
    -o-object-fit: cover;
    width: 100%;
  }
}
</style>
