const server = process.env.VUE_APP_SERVER;

const menu = [
  {
    text: "Início",
    to: "/",
  },
  {
    text: "Fale Conosco",
    to: "/fale-conosco",
  },
];

export { server, menu };
